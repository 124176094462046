import React from "react";
import styled from "styled-components";
// Assets
// import ContactImg1 from "../../assets/img/contact-1.png";
// import ContactImg2 from "../../assets/img/contact-2.png";
// import ContactImg3 from "../../assets/img/contact-3.png";
import DeploySVG from "../../assets/animations/deploy.svg";
import FullButton from "../Buttons/FullButton";

export default function Contact() {

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };


  return (
    <Wrapper id="contact">
      <div>
        <div className="container orangeBg radius8">
          
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
             <HeaderInfo>
            <h1 className="font32 extraBold">Unleash the power of computer vision and increase operational efficiency at your organisation</h1>
            <br/>
            <br/>
            <p className="font13 yellow">
              Let's discuss your use case
              </p>
          </HeaderInfo> 
              <SumbitWrapper className="flex">  
             <a href="https://forms.gle/gALpoW2J2ksXuBBk6"  target='_blank' rel="noopener noreferrer"> <FullButton title="Get Access" width='200px' /> </a>
              </SumbitWrapper>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              {/* <div style={{ width: "50%" }} className="flexNullCenter flexColumn"> */}
                <ContactImgBox>
                <img src={DeploySVG} height="300px" width="400px" alt="office" className="radius6" />
                </ContactImgBox>
                
              {/* </div> */}
              {/* <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <img src={DeploySVG} alt="office" className="radius6" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
margin-top: 80px;
margin-bottom: 80px;
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 20px 0 30px 0;
  color: #ffffff;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const ButtonInput = styled.button`
  border: 1px solid #707070;
  background-color: #F9F33B;
  width: 100%;
  padding: 15px;
  outline: none;
  font-size: 16px;
  color: #707070;
  :hover {
    background-color: transparent;
    border: 1px solid #F9F33B;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
padding: 40px 0 0 0;
  align-self: flex-center; 
  // margin: 10px 30px 10px 0;
  @media (max-width: 500px) {
    padding: 100px 0 0 120px;

  }
`;
const SumbitWrapper = styled.div`
width: 60%;
  @media (max-width: 991px) {
    width: 80%;
    margin-bottom: 50px;
  }
`;









