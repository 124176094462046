import Rive from '@rive-app/react-canvas';
import Iframe from 'react-iframe'
import styled from "styled-components";


export const HeroAnimation = () => (
    <Iframe  title='Wono' width="500" height="400" src="https://rive.app/s/PEQKACkiW0uy8PIAThliIA/embed" />

);

