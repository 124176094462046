import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width="40" height="40" viewBox="0 0 243 244" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M178.297 238.001C196.581 236.255 214.324 233.957 231.067 231.677C231.514 231.616 231.864 231.264 231.922 230.816C234.052 214.429 236.282 196.946 238 178.901" stroke="#6ED655" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M64.7029 5C46.419 6.74635 28.6762 9.04408 11.9331 11.3242C11.4857 11.3851 11.1356 11.7374 11.0774 12.1851C8.94765 28.5722 6.71801 46.0548 5 64.0998" stroke="#6ED655" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M178.297 5C196.581 6.74635 214.324 9.04408 231.067 11.3242C231.514 11.3851 231.864 11.7374 231.922 12.1851C234.052 28.5722 236.282 46.0548 238 64.0998" stroke="#6ED655" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M64.7029 238.001C46.419 236.255 28.6762 233.957 11.9331 231.677C11.4857 231.616 11.1356 231.264 11.0774 230.816C8.94765 214.429 6.71801 196.946 5 178.901" stroke="#6ED655" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="121" cy="128" r="100" fill="#F9F33B"/>
    <mask id="path-6-inside-1_50_325" fill="white">
    <path d="M161 150.5C161 161.241 156.733 171.543 149.138 179.138C141.543 186.733 131.241 191 120.5 191C109.759 191 99.4574 186.733 91.8622 179.138C84.267 171.543 80 161.241 80 150.5L120.5 150.5H161Z"/>
    </mask>
    <path d="M161 150.5C161 161.241 156.733 171.543 149.138 179.138C141.543 186.733 131.241 191 120.5 191C109.759 191 99.4574 186.733 91.8622 179.138C84.267 171.543 80 161.241 80 150.5L120.5 150.5H161Z" fill="#664500" stroke="#664500" stroke-width="4" mask="url(#path-6-inside-1_50_325)"/>
    <circle cx="160" cy="103" r="24" fill="white" stroke="black" stroke-width="2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M160.134 91.4178C158.531 97.2811 152.48 100.736 146.616 99.1335C144.416 98.5321 142.554 97.3038 141.172 95.6938C140.996 96.1821 140.838 96.6812 140.699 97.1904C137.841 107.649 144.003 118.444 154.462 121.302C164.921 124.159 175.715 117.997 178.573 107.539C181.431 97.0802 175.269 86.2852 164.81 83.4276C162.829 82.8862 160.835 82.6686 158.886 82.7425C160.428 85.246 160.97 88.3576 160.134 91.4178ZM164.522 110.621C166.78 110.621 168.612 108.79 168.612 106.532C168.612 104.273 166.78 102.442 164.522 102.442C162.263 102.442 160.432 104.273 160.432 106.532C160.432 108.79 162.263 110.621 164.522 110.621Z" fill="black"/>
    <circle cx="78" cy="103" r="24" fill="white" stroke="black" stroke-width="2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M78.1335 91.4178C76.5315 97.2811 70.4797 100.736 64.6164 99.1335C62.4155 98.5321 60.554 97.3038 59.172 95.6938C58.9962 96.1821 58.8382 96.6812 58.6991 97.1904C55.8415 107.649 62.0033 118.444 72.4619 121.302C82.9205 124.159 93.7155 117.997 96.5731 107.539C99.4307 97.0802 93.2689 86.2852 82.8103 83.4276C80.8289 82.8862 78.8354 82.6686 76.8861 82.7425C78.4284 85.246 78.9697 88.3576 78.1335 91.4178ZM82.5217 110.621C84.7805 110.621 86.6116 108.79 86.6116 106.532C86.6116 104.273 84.7805 102.442 82.5217 102.442C80.263 102.442 78.4319 104.273 78.4319 106.532C78.4319 108.79 80.263 110.621 82.5217 110.621Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M36.9092 87.0307C38.1265 88.219 44.2029 91.7889 45.4151 95.3588C46.6324 98.9237 49.067 119.145 56.3607 125.096C63.8826 131.222 92.2559 128.944 97.6882 126.289C109.846 120.348 111.337 103.761 113.488 95.3588C114.7 90.6006 121.994 90.6006 121.994 90.6006C121.994 90.6006 129.287 90.6006 130.505 95.3538C132.655 103.761 134.157 120.348 146.304 126.284C151.742 128.944 180.115 131.227 187.647 125.101C194.93 119.15 197.36 98.9287 198.577 95.3588C199.784 91.7939 205.871 88.224 207.083 87.0357C208.306 85.8474 208.306 81.0892 207.083 79.896C204.654 77.5193 175.971 75.4013 145.087 78.7076C138.924 79.3689 136.581 81.0843 121.989 81.0843C107.406 81.0843 105.053 79.3639 98.8953 78.7076C68.0316 75.3963 39.3438 77.5144 36.9092 79.891C35.6969 81.0793 35.6969 85.8424 36.9092 87.0307V87.0307Z" fill="#1A1D20"/>
    </svg>
    
  );
}

export default SvgComponent;
