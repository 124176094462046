
import React from "react";
import styled from "styled-components";
// Components
import Deploy from "../Deploy";
import OneImage from "../OneImage";
import Generate from "../Generate";
import Annotate from "../Annotate";
import Banner from "./banner";
// Assets
import AnnonateSVG from "../../assets/animations/annotate.svg";
import OneImageSVG from "../../assets/animations/upload.svg";
import GenerateSVG from "../../assets/animations/train.svg";
import DeploySVG from "../../assets/animations/deploy.svg";



export default function Benefits() {

  return (
    <Wrapper id="benefits">
      <div className="whiteBg">
        <div className="container ">
          <HeaderInfo>
            <Title className="extraBold"></Title>
            <Subtitle className="font13">
            The Wono Advantage
            </Subtitle>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
               <CardWrapper>
                <ImgBtn className="aniamte pointer" >
                  <OneImage/>
                  </ImgBtn>
                  <FeatureTitle className="font20 extraBold">One Image</FeatureTitle>
                   <FeatureText className="font13">Get started with as low as one image of the object of interest</FeatureText>
                   </CardWrapper>
            </div>
            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
            <CardWrapper>
                <ImgBtn className="aniamte pointer" >
                <Generate/>
                  </ImgBtn>
                  <FeatureTitle className="font20 extraBold">Imagination</FeatureTitle>
                   <FeatureText className="font13">Wono will generate variations based on the image you provide, saving you days of data collection time!</FeatureText>
                   </CardWrapper>
            </div>
            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
            <CardWrapper>
                <ImgBtn className="aniamte pointer" >
                 
                  <Annotate/>
                  </ImgBtn>
                  <FeatureTitle className="font20 extraBold">Automated Annotation</FeatureTitle>
                   <FeatureText className="font13">All generated variations are automatically marked and annotated</FeatureText>
                   </CardWrapper>
            </div>
            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
            <CardWrapper>
                <ImgBtn className="aniamte pointer" >
                  <Deploy/>
                  </ImgBtn>
                  <FeatureTitle className="font20 extraBold">Train and Deploy</FeatureTitle>
                   <FeatureText className="font13">Train on our poweful machines and Deploy the AI on the edge such as a mobile phones, CCTV Camera, Robot etc</FeatureText>
                   </CardWrapper>
            </div>
          </div>
          <br/>
          <br/>
          <br/>
         
       
        </div>
       
      </div>
      <Banner />
      <div>
        <div className="container" >
        <HeaderInfo2>

            <Title className="extraBold"></Title>
            <Subtitle className="font13">
            How it works
            </Subtitle>
          </HeaderInfo2>
          
          <Steps className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
              <How className="font15 semiBold">01</How>
              <HowHeader>Upload 1 Image</HowHeader>
              <HowText >
               Feed your model with at least 1 image, to generate 1000+ variants. You can guide the pipeline to generate the variants based on your use-case.
              </HowText>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
            <ImgWrapper className="flex">
            <img className="radius8" src={OneImageSVG}  alt="add" />
                </ImgWrapper>
            </AddRight>
          </Steps>
          <Steps className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AnnonateSVG}  alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <How className="font15 semiBold">02</How>
              <HowHeader>Auto Annotates</HowHeader>
              <HowText >
               Our pipeline takes care of all the annotations for the generated variations. Your dataset is ready for training within a few hours.
              </HowText>
            </AddRight>
          </Steps>
          <Steps className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
              <How className="font15 semiBold">03</How>
              <HowHeader>Training</HowHeader>
              <HowText >
               Kick-start the training on our superfast VMs. This should take less than a couple of days.
              </HowText>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
            <ImgWrapper className="flexCenter">
            <img className="radius8" src={GenerateSVG}  alt="add" />
                </ImgWrapper>
            </AddRight>
          </Steps>
          <Steps className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                <img className="radius8" src={DeploySVG}  alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <How className="font15 semiBold">04</How>
              <HowHeader>Deploy</HowHeader>
              <HowText >
               Export and Deploy the model on your edge devices, or use our Mobile app. Your computer vision use case is ready under a week!
              </HowText>
            </AddRight>
          </Steps>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  margin-top:100px;
`;

const Subtitle = styled.h1`
font-size: 60px;
color: #F9F33B;
`;

const Title = styled.h1`
font-size: 30px;
color: #ffffff;
`;

const FeatureTitle = styled.h3`
font-size: 26FeatureTextx;
color: #F9F33B;
`;

const FeatureText = styled.p`
font-size: 18px;
color: #ffffff;
`;

const How = styled.h1`
font-size: 50px;
font-weight: 400;
color: #F9F33B;
`;

const HowHeader = styled.h1`
font-size: 40px;
color: #ffffff;
`;

const HowText = styled.h1`
font-size: 20px;
font-weight: 300;
color: #ffffff;
`;



const HeaderInfo = styled.div`
text-align: center;
margin-bottom:100px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const HeaderInfo2 = styled.div`
text-align: center;
margin: 200px 0 0 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const Steps = styled.div`
  padding: 100px 0;
  margin-left: 100px;
  margin-right: 100px;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 100%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    // p {
    //   margin: 0 auto;
    // }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: relative;
  
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    // p {
    //   margin: 0 auto;
    // }
  }
`;
const AddLeftInner = styled.div`
  width: 80%;
  // position: absolute;
  // top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    // top: -250px;
  }
  @media (max-width: 920px) {
    // top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    // top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 50%;
  align:center;
  display: flex;
  justify-content: center;
  // padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
const CardWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  img {
    width: 100%;
    height: auto;
    margin: 20px 0;
  }
  FeatureTitle {
    padding-bottom: 10px;
  }
`;
const ImgBtn = styled.button`
  background-color: transparent;
  height: 200px;
  width: 200px;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  :hover > img {
    opacity: 0.5;
  }
`;