import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
import Retail from "../../assets/img/retail.png";
import Construction from "../../assets/img/construction.png";
import AR from "../../assets/img/AR.png";
import Agriculture from "../../assets/img/agriculture.png";
import Medicine from "../../assets/img/medicine.png";
import Manufacturing from "../../assets/img/manufacture.png";


export default function UseCase() {
  return (
    <Wrapper id="use-case">
      <div >
        <div className="container">
          <HeaderInfo>
            <h1 className="extraBold">Use Cases</h1>
            {/* <p className="font13 whiteColor">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p> */}
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <CardWrapper className="shadowDark radius8">
                <ImgBtn>
                  <img className="radius8" src={AR} alt="project"></img>
                </ImgBtn>
                <Title> Augmented Reality
                  <br />
                  
                <Text>Academics - Consumer Education - Self-Service Tutorials</Text>
                </Title>
                 <br />
                 <br />
                 
                {/*<div className="flexCenter" style={{ width: "200px " }}>
                  <FullButton
                    className="animate pointer tag flexCenter"
                    onClick={""}
                    title="Learn More"
                  />
                </div> */}
              </CardWrapper>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <CardWrapper className="shadowDark radius8">
                <ImgBtn>
                  <img className="radius8" src={Construction} alt="project"></img>
                </ImgBtn>
                <Title> Construction
                  <br />
                  
                <Text>Predictive Maintenance - Safety Management - PPE Detection - Compliance Auditing</Text>
                </Title>
                 <br />
                {/*<div className="flexCenter" style={{ width: "200px " }}>
                  <FullButton
                    className="animate pointer tag flexCenter"
                    onClick={""}
                    title="Learn More"
                  />
                </div> */}
              </CardWrapper>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <CardWrapper className="shadowDark radius8">
                <ImgBtn>
                  <img className="radius8" src={Manufacturing} alt="project"></img>
                </ImgBtn>
                <Title> Manufacturing
                  <br />
                  
                <Text>Safety Management - Quality Control - Increase Productivity - Inventory management - Compliance</Text>
                </Title>
                 <br />
                {/*<div className="flexCenter" style={{ width: "200px " }}>
                  <FullButton
                    className="animate pointer tag flexCenter"
                    onClick={""}
                    title="Learn More"
                  />
                </div> */}
              </CardWrapper>
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <CardWrapper className="shadowDark radius8">
                <ImgBtn>
                  <img className="radius8" src={Agriculture} alt="project"></img>
                </ImgBtn>
                <Title> Agriculture
                  <br />
                  
                <Text>Automated picking/weeding - Plant disease classification - Livestock Monitoring - Harvest optimization</Text>
                </Title>
                
                {/*<div className="flexCenter" style={{ width: "200px " }}>
                  <FullButton
                    className="animate pointer tag flexCenter"
                    onClick={""}
                    title="Learn More"
                  />
                </div> */}
              </CardWrapper>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <CardWrapper className="shadowDark radius8">
                <ImgBtn>
                  <img className="radius8" src={Medicine} alt="project"></img>
                </ImgBtn>
                <Title> Pharmaceuticals
                  <br />
                  
                <Text>Capsule Quality Inspection - Imprint detection - Package Defect Detection</Text>
                </Title>
                 <br />
                {/*<div className="flexCenter" style={{ width: "200px " }}>
                  <FullButton
                    className="animate pointer tag flexCenter"
                    onClick={""}
                    title="Learn More"
                  />
                </div> */}
              </CardWrapper>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <CardWrapper className="shadowDark radius8">
                <ImgBtn>
                  <img className="radius8" src={Retail} alt="project"></img>
                </ImgBtn>
                <Title> Retail
                  <br />
                  
                  
                <Text>Catalog Management - Inventory - Auto Checkout -  Shelf Monitoring and Auditing</Text>
                </Title>
                 <br />
                
                {/*<div className="flexCenter" style={{ width: "200px " }}>
                  <FullButton
                    className="animate pointer tag flexCenter"
                    onClick={""}
                    title="Learn More"
                  />
                </div> */}
              </CardWrapper>
            </div>
          </div>
          {/* <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Load More" action={() => alert("clicked")} />
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">What They Say?</h1>
            <p className="font13">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div> */}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
text-align: center;
font-size: 60px;
color: #F9F33B;
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const CardWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  background-color:#261461;
  img {
    border: 0.5px solid #F9F33B;
    width: 90%;
    height: auto;
    margin: 20px 0;
  }
  h3 {
    padding-bottom: 10px;
  }
`;
const ImgBtn = styled.button`
  background-color: transparent;
  filter: drop-shadow(0px 1px 16px rgba(249, 243, 59, 0.12));
  border: 0px;
  // width: 90%;
  // height:100px;
  outline: none;
  // padding: 0px;

  // :hover > img {
  //   opacity: 0.5;
  // }
`;

const Title = styled.h1`
text-align: start;
padding-left: 8%;
font-size: 20px;
color: #F9F33B;
`;

const Text = styled.h5`
font-size: 16px;
font-weight: 100;
color: #ffffff;
padding-bottom: 12px;
padding-right: 8%;
`;

