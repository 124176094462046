import React from "react";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";

export default function App() {
  return (
    <>
      <Helmet>
        
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
        <title>Wono AI</title>
        <meta
          name="description"
          content="Optimise operations at your organisation with high accuracy.."
        />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Wono" />
        <meta
          itemprop="description"
          content="Optimise operations at your organisation with high accuracy.."
        />
        <meta
          itemprop="image"
          content="https://wonoai.com/static/media/AR.c85d94e8.png"
        />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://wonoai.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Wono" />
        <meta
          property="og:description"
          content="Optimise operations at your organisation with high accuracy.."
        />
        <meta
          property="og:image"
          content="https://wonoai.com/static/media/AR.c85d94e8.png"
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="https://wonoai.com/static/media/AR.c85d94e8.png" />
        <meta name="twitter:title" content="Wono" />
        <meta
          name="twitter:description"
          content="Optimise operations at your organisation with high accuracy.."
        />
        <meta
          name="twitter:image"
          content="https://wonoai.com/static/media/AR.c85d94e8.png"
        />
      </Helmet>
      <Landing />
    </>
  );
}