import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Benefits from "../components/Sections/Benefits";
import UseCase from "../components/Sections/UseCase";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"

export default function Landing() {
  return (
    <>
      <TopNavbar />
      <Header />
      {/* <Services /> */}
      <Benefits />
      <UseCase />
      {/* <Pricing /> */}
      <Contact />
      <Footer />
    </>
  );
}


