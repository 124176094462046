import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border }) {
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };


  return (
    <Wrapper
      className="animate pointer radius12"
      // onClick={()=>openInNewTab('https://forms.gle/gALpoW2J2ksXuBBk6')}
      border={border}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  // border: 1px solid ${(props) => (props.border ? "#707070" : "#7620ff")};
  background-color: ${(props) => (props.border ? "transparent" : "#F9F33B")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#0A041C" : "#0A041C")};
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#0A041C")};
    border: 1px solid #F9F33B;
    color: ${(props) => (props.border ? "#7620ff" : "#fff")};
  }
`;

