import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
// import HeaderImage from "../../assets/img/header-img.png";
import { HeroAnimation } from "../HeroAnimation";




export default function Header() {
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };


  return (
    <Wrapper id="home" className=" flexSpaceCenter">
      <LeftSide className="container flexCenter">
        <div>
          <h1 className="extraBold font60 whiteColor">  We Only Need One</h1>
          <HeaderP className="font22 semiBold whiteColor">
          <br/>
          ...image to train and build your personal AI that can instantly recognise objects in real-time using computer vision
          <br/>
          <br/>
          </HeaderP>
          <HeaderP className="font22 whiteColor">
          go from concept to deployment in days not months
          </HeaderP>
          <BtnWrapper >
          <a href="https://forms.gle/gALpoW2J2ksXuBBk6"  target='_blank' rel="noopener noreferrer">  <FullButton title="Get Access"  /></a>
          </BtnWrapper>
        </div>
      </LeftSide>
      <RightSide>
      <HeroAnimation />
        {/* <ImageWrapper> */}
          {/* <Img className="radius8" src={HeaderImage} alt="office" style={{zIndex: 9}} /> */}
          
        {/* </ImageWrapper> */}
       
      </RightSide>
    </Wrapper>
  );
}



const Wrapper = styled.section`
  padding-top: 40px;
  background-image: linear-gradient(to left bottom, #39117b, #261461, #191347, #120e2d, #050213);
  width: 100%;
  min-height: 800px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  margin-left: 220px;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  // margin-right: 120px;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
  @media (max-width: 400px) {
    width: 10%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;

  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
   
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;

const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


